.p-error {
  border: 2px solid transparent;
  border-radius: 4px;
  background: $red;
  color: white;
  text-align: center;
}

.p-points {
  border: 2px solid transparent;
  border-radius: 4px;
  background: $green;
  color: white;
  text-align: center;
}
