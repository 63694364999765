@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600");

@import "functions";
@import "variables";

// variables overrides
$body-color: #1f202e;
$text-muted: #6C757D;

$theme-colors: map-merge(
	$theme-colors,
	(
		"primary": #0078D4,
		"secondary": #ededed,
		"dark": #6c757d,
		"danger": #fd3467,
	)
);

$table-color: $body-color;

$font-weight-bold: 600;
$font-weight-bolder: 600;

$font-family-sans-serif: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-base: $font-family-sans-serif;
$small-font-size: 87.5%;
$line-height-base: 1.375;

$headings-font-family: $font-family-sans-serif;
$headings-font-weight: 400;
$headings-line-height: 1.375;

$display4-size: 1.375rem;

$btn-font-weight: $font-weight-bold;

$btn-primary-hover: #006CBE;
$btn-primary-disabled: #80BCEA;
$btn-primary-active: #005494;
$btn-primary-active-border: #ADD2FF;
$btn-primary-outline-hover: #E5F1FA;

$btn-success-hover: #14a130;
$btn-success-disabled: #8dd89c;
$btn-success-active: #2E8540;
$btn-success-active-border: #618168;
$btn-success-outline-hover: #c0cbc2;

$input-color: #1F202E;
$input-border-color: #8E969B;
$input-focus-border-color: #ADD2FF;
$input-placeholder-color: #6C757D;

@import "mixins";
@import "reboot";
@import "type";
@import "tables";
@import "forms";
@import "buttons";
@import "input-group";
@import "utilities";
@import "paragraphs";
@import "components/loyalty";
@import "components/pay-now-button";
@import "components/credit-card-i4go-iframe";

body {
	&.success {
		background-color: #2e8540;
		color: #ffffff;
	}
	&.error {
		background-color: #cd2026;
		color: #ffffff;
	}
}

.toolbar {
	max-width: 576px;
	margin: 0 auto;
	@extend .pl-2;
	@extend .pr-2;
}

.content {
	max-width: 576px;
	width: 100%;
	margin: 0 auto;
}

#logo {
	@include img-fluid();
	display: block;
	margin: 0 auto;
}
#logo-text {
	@extend .title;
	@extend .text-center;
	margin-top: 16px;
	height: 105px;
}

.dot {
	height: 10px;
	width: 10px;
	border-radius: 50%;
	display: inline-block;
	margin-left: 8px;
	&.dot-1 { background-color: #f7c325; }
	&.dot-2 { background-color: #00AD1B; }
	&.dot-3 { background-color: #358cf5; }
	&.dot-4 { background-color: #ff0000; }
	&.dot-5 { background-color: #949494; }
	&.dot-6 { background-color: #DE40E7; }
}

.alert {
	position: relative;
	padding: 0.8rem 1.25rem;
	margin-bottom: 1rem;
	border: 1px solid transparent;
	border-radius: 0.25rem;
}
.alert-primary {
	color: #0078D4;
	background-color: #f0f6fe;
	border-color: #f0f6fe;
}

#btn-back {
	@extend .btn;
	@extend .p-0;
	background-image: url('/img/back.png');
	height: 24px;
	width: 24px;
}
.btn-pay {
	height: 40px;
	display: block;
	width: 100%;
}
.btn-error {
	@extend .btn-light;
	color: #fd3467;
	&:hover {
		color: #fd3467;
	}
}

.google-pay,
.crypto-pay {
	&, button {
		width: 100% !important;
	}
	button {
		margin-bottom: 0.5rem;
	}
}

.crypto-pay button {
	background-color: $white;
	color: map-get($theme-colors, 'primary');
}

.hide {
	visibility: hidden !important;
	display: none !important;
}

.lds-spinner {
	color: #358cf5;
	display: inline-block;
	position: relative;
	width: 100px;
	height: 100px;
}
.lds-spinner div {
	transform-origin: 40px 40px;
	animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
	content: " ";
	display: block;
	position: absolute;
	top: 3px;
	left: 37px;
	width: 6px;
	height: 18px;
	border-radius: 20%;
	background: #358cf5;
}
.lds-spinner div:nth-child(1) {
	transform: rotate(0deg);
	animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
	transform: rotate(30deg);
	animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
	transform: rotate(60deg);
	animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
	transform: rotate(90deg);
	animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
	transform: rotate(120deg);
	animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
	transform: rotate(150deg);
	animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
	transform: rotate(180deg);
	animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
	transform: rotate(210deg);
	animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
	transform: rotate(240deg);
	animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
	transform: rotate(270deg);
	animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
	transform: rotate(300deg);
	animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
	transform: rotate(330deg);
	animation-delay: 0s;
}
@keyframes lds-spinner {
	0% { opacity: 1; }
	100% { opacity: 0; }
}

.i4go-frame {
	height: calc(var(--vh) * 100 - 105px);
}
.pay-wrapper {
	height: calc(var(--vh) * 100 - 214px);
}

.title {
	font-size: 22px;
	font-weight: 600;
}

.footer-page {
	display: flex;
	flex-flow: column;
	height: calc(var(--vh, 1vh) * 100);
	.footer-form {
		flex-grow: 1;
	}
}

.footer-banner {
	background: #ffffff;
	color: #1f202e;
	padding: 10px 0;
	text-align: center;
}

.loyalty-container {
    position: relative;
    pointer-events: none;
}

.loyalty {
    opacity: 25%;
}
.loyalty-spinner {
    position: absolute;
    top: 60%;
    right: 38%;
 }

@media (min-width: 480px) {
  .loyalty-spinner {
    position: absolute;
    top: 40%;
    right: 40%;
  }
}

.wallets-loaded {
	display: none !important;
}

.button-divider {
	display: flex;
	align-items: center;
	width: 100%;
	height: 24px;
	margin-bottom: 8px;

	&::before,
	&::after {
		content: "";
		flex: 1;
		border-bottom: 1px solid $gray-400;
		height: 0;
	}

	span {
	  margin: 0 10px;
	  color: $body-color;
	}
}
