.modal {
	display: none;
	position: fixed;
	z-index: 1000;
	inset: 0;
	background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
	background-color: $white;
	margin: 10% auto;
	padding: 20px;
	width: 90%;
}

.threeDsFrame {
	position: relative; 
	height: 450px;
}

.modal.displayThreeDsOtp {
	display: block;
}
