@use "sass:map";
// stylelint-disable selector-no-qualifying-type

//
// Base styles
//

.btn {
  display: inline-block;
  font-family: $btn-font-family;
  font-weight: $btn-font-weight;
  color: $body-color;
  text-align: center;
  text-decoration: if($link-decoration == none, null, none);
  white-space: $btn-white-space;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: $btn-border-width solid transparent;
  @include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $btn-line-height, $btn-border-radius);
  @include transition($btn-transition);

  @include hover() {
    color: $body-color;
    text-decoration: none;
  }

  &:focus,
  &.focus {
    outline: 0;
    box-shadow: $btn-focus-box-shadow;
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    opacity: $btn-disabled-opacity;
    @include box-shadow(none);
  }

  &:not(:disabled):not(.disabled) {
    cursor: if($enable-pointer-cursor-for-buttons, pointer, null);

    &:active,
    &.active {
      @include box-shadow($btn-active-box-shadow);

      &:focus {
        @include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
      }
    }
  }
}

// Future-proof disabling of clicks on `<a>` elements
// a.btn.disabled,
// fieldset:disabled a.btn {
//   pointer-events: none;
// }


//
// Alternate buttons
//

// @each $color, $value in $theme-colors {
//   .btn-#{$color} {
//     @include button-variant($value, $value);
//   }
// }

.btn-primary {
  @include button-variant(
    map.get($theme-colors, 'primary'),
    map.get($theme-colors, 'primary'),
    $btn-primary-hover,
    $btn-primary-hover,
    $btn-primary-active,
    $btn-primary-active-border
  );
  &.disabled, &:disabled {
    background-color: $btn-primary-disabled !important;
    border-color: $btn-primary-disabled !important;
    opacity: 1.1 !important;
  }
}

.btn-success {
  @include button-variant(
    map.get($theme-colors, 'success'),
    map.get($theme-colors, 'success'),
    $btn-success-hover,
    $btn-success-hover,
    $btn-success-active,
    $btn-success-active-border
  );
  &.disabled, &:disabled {
    background-color: $btn-success-disabled !important;
    border-color: $btn-success-disabled !important;
    opacity: 1.1 !important;
  }
}

.btn-secondary {
  @include button-variant(map.get($theme-colors, 'secondary'), map.get($theme-colors, 'secondary'));
}

.btn-dark {
  @include button-variant(map.get($theme-colors, 'dark'), map.get($theme-colors, 'dark'));
}

.btn-light {
  @include button-variant(map.get($theme-colors, 'light'), map.get($theme-colors, 'light'));
}

// @each $color, $value in $theme-colors {
//   .btn-outline-#{$color} {
//     @include button-outline-variant($value);
//   }
// }

.btn-outline-primary {
  $color: map.get($theme-colors, 'primary');
  $active-background: $btn-primary-outline-hover;

  color: $color;
  border-color: $color;
  $color-hover: $color;
  $active-border: $color;

  @include hover() {
    color: $color-hover;
    background-color: $active-background;
    border-color: $active-border;
  }

  &:focus,
  &.focus {
    box-shadow: 0 0 0 $btn-focus-width $btn-primary-active-border;
  }

  &.disabled,
  &:disabled {
    color: $btn-primary-disabled;
    border-color: $btn-primary-disabled;
    opacity: 1.1 !important;
    background-color: transparent;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    color: $color;
    background-color: $active-background;
    border-color: $active-border;

    &:focus {
      @if $enable-shadows and $btn-active-box-shadow != none {
        @include box-shadow($btn-active-box-shadow, 0 0 0 $btn-focus-width rgba($color, .5));
      } @else {
        // Avoid using mixin so we can pass custom focus shadow properly
        box-shadow: 0 0 0 $btn-focus-width rgba($color, .5);
      }
    }
  }
}

//
// Link buttons
//

// Make a button look and behave like a link
// .btn-link {
//   font-weight: $font-weight-normal;
//   color: $link-color;
//   text-decoration: $link-decoration;

//   @include hover() {
//     color: $link-hover-color;
//     text-decoration: $link-hover-decoration;
//   }

//   &:focus,
//   &.focus {
//     text-decoration: $link-hover-decoration;
//   }

//   &:disabled,
//   &.disabled {
//     color: $btn-link-disabled-color;
//     pointer-events: none;
//   }

//   // No need for an active state here
// }


//
// Button Sizes
//

// .btn-lg {
//   @include button-size($btn-padding-y-lg, $btn-padding-x-lg, $btn-font-size-lg, $btn-line-height-lg, $btn-border-radius-lg);
// }

.btn-sm {
  @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $btn-font-size-sm, $btn-line-height-sm, $btn-border-radius-sm);
}


//
// Block button
//

.btn-block {
  display: block;
  width: 100%;

  // Vertically space out multiple block buttons
  + .btn-block {
    margin-top: $btn-block-spacing-y;
  }
}

// Specificity overrides
input[type="submit"],
input[type="reset"],
input[type="button"] {
  &.btn-block {
    width: 100%;
  }
}
