.pay-now-button {
	button,
	span {
		align-items: center;
		justify-content: center;
		display: inline-flex;
	}

	span {
		&:before {
			height: 24px;
			margin-right: 8px;
			width: 32px;
		}

		&.vs {
			&:before {
				content: url("/img/card-icons/visa.svg");
			}
		}

		&.ax {
			&:before {
				content: url("/img/card-icons/amex.svg");
			}
		}

		&.mc {
			&:before {
				content: url("/img/card-icons/mastercard.svg");
			}
		}

		&.ns {
			&:before {
				content: url("/img/card-icons/discover.svg");
			}
		}

		&.jc {
			&:before {
				content: url("/img/card-icons/jcb.svg");
			}
		}

		&.unknown {
			&:before {
				content: url("/img/card-icons/unknown.svg");
			}
		}
	}
}
