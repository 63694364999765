.loyalty-member {
	.p-points {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: 0;

		span.balance {
			flex: 1;
		}

		.btn {
			padding: 0.185rem 0.375rem;
		}
	}
}
