// stylelint-disable selector-no-qualifying-type
// Base styles

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap; // For form validation feedback
  align-items: stretch;
  width: 100%;

  > .clear {
    border: 0;
    padding: 0;
    font-family: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 90px;
    width: 16px;
    height: 16px;
    background-color: map-get($grays, '500');
    border-radius: 50%;
    cursor: pointer;
    align-self: center;

    &:focus {
      outline: none;
    }

    > .cross-line {
      position: absolute;
      width: 10px;
      height: 2px;
      background-color: map-get($colors, 'white');
      transform: rotate(45deg);

      &:last-child {
        transform: rotate(-45deg);
      }
    }
  }

  > .form-control {
    position: relative; // For focus state's z-index
    flex: 1 1 auto;
    width: 1%;
    min-width: 0; // https://stackoverflow.com/questions/36247140/why-dont-flex-items-shrink-past-content-size
    margin-bottom: 0;

    + .form-control {
      margin-left: -$input-border-width;
    }
  }

  > .form-control {
    &:not(:last-child) { @include border-right-radius(0); }
    &:not(:first-child) { @include border-left-radius(0); }
  }
}


// Prepend and append
//
// While it requires one extra layer of HTML for each, dedicated prepend and
// append elements allow us to 1) be less clever, 2) simplify our selectors, and
// 3) support HTML5 form validation.

.input-group-prepend,
.input-group-append {
  display: flex;

  // Ensure buttons are always above inputs for more visually pleasing borders.
  // This isn't needed for `.input-group-text` since it shares the same border-color
  // as our inputs.
  .btn {
    position: relative;
    z-index: 2;

    &:focus {
      z-index: 3;
    }
  }

  .btn + .btn,
  .btn + .input-group-text,
  .input-group-text + .input-group-text,
  .input-group-text + .btn {
    margin-left: -$input-border-width;
  }
}

.input-group-prepend { margin-right: -$input-border-width; }
.input-group-append { margin-left: -$input-border-width; }

// Prepend and append rounded corners
//
// These rulesets must come after the sizing ones to properly override sm and lg
// border-radius values when extending. They're more specific than we'd like
// with the `.input-group >` part, but without it, we cannot override the sizing.

.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  @include border-right-radius(0);
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text {
  @include border-left-radius(0);
}
